/* About */

.about-container {
    min-height: 100vh;
  }
  
  .smuggler-pic {
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .blaster-container {
    position: initial;
    width: auto;
    height: auto;
  }
  
  .blaster1 {
    display: none;
    position: relative;
    background-color: #d61a1f;
    width: 20px;
    height: 5px;
    left: 80px;
    top: 220px;
    content: "wah";
    animation-name: blasterturn;
    -webkit-animation-name: blasterturn;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }
  
  .blaster2 {
    display: none;
    position: relative;
    background-color: #d61a1f;
    width: 20px;
    height: 5px;
    left: 105px;
    top: 75px;
    content: "wah";
    animation-name: blasterturn;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 5s;
    animation-iteration-count: infinite;
  }
  
  @keyframes blasterfire {
    from {left: 105px}
    to {left: -200px}
  }
  
  @keyframes blasterturn {
    0% {left: 10%; inset: 45% 10%; transform: skew(10deg, 10deg)}
    20%, 100% {left: -25vw; inset: 30% -25vw; transform: skew(10deg, 10deg)}
  }