.work-experience h4 {
    font-size: medium;
}

.work-experience li {
    font-size: small;
    margin-bottom: 1em;
}

.accordion-body {
    text-align: justify;
}

.work-experience button {
    font-size: small;
}