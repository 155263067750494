.App {
  text-align: center;
  width: 100%;
  background: #000;
  font-family: 'Jetbrains Mono', monospace;
  box-sizing: border-box;
}

h1 {
  --bs-heading-color: #3366ff;
}

p {
  color: #fff;
}

a {
  /* color: #d61a1f; */
  color: #3366ff;
}

a:hover {
  color: grey;
}

.container {
  display: flex;
  max-width: max-content;
  max-height: max-content;
}

.container-fluid > .row {
  min-height: 100vh;
}