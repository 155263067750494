.icon-bar {
    position: fixed;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .icon-bar div {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
  }
  
  .icon-bar div:hover {
    background-color: #000;
  }

  .linkedin {
    background-color: #0d6efd;
  }

  .github {
    background-color: #6c757d;
  }

  .resume {
    background-color: #ff0000;
  }