.blog-post {
    text-align: left;
    margin: 2em;
    padding: 1em;
    color: #fff;
    background-color: rgba(255,255,255,0.1);
}

.blog-post-back-btn {
    margin-bottom: 2em;
}

h2 {
    color: #ff0;
}

h3 {
    color: #fff;
}

table {
    color: #fff;
    margin-bottom: 1em;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}